<aside class="app-sidebar">
    <ul>
        <!-- <li><a routerLink="/dashBoard">Dashboard</a></li> -->
        <!-- <li><a routerLink="/cluster">Define Cluster</a></li> -->
        <li><a routerLink="/user">User</a></li>
        <li><a (click)="detectRoute()" >Check In List</a></li>
        <li><a routerLink="/store">Store</a></li>


        <!-- <li> <a routerLink="/checkIn"> checkIn </a> </li>
        <li><a href="#">Profile</a></li>
        <li><a href="#">Logout</a></li> -->
    </ul>
</aside>