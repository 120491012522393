import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-logout-dialog',
  standalone: true,
  imports: [MatDialogModule],
  template: `
    <h2 mat-dialog-title>Confirm Logout</h2>
    <mat-dialog-content>Are you sure you want to log out?</mat-dialog-content>
    <mat-dialog-actions>
      <button class="cancel-btn" mat-button (click)="onCancel()">Cancel</button>
      <button class="logout-btn" mat-button (click)="onConfirm()">Logout</button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .cancel-btn {
        color: #ffffff;
        background-color: #2196f3;
        border-radius: 4px;
        padding: 8px 16px;
        margin-right: 8px;
      }

      .cancel-btn:hover {
        background-color: #1976d2;
      }

      .logout-btn {
        color: #ffffff;
        background-color: #f44336;
        border-radius: 4px;
        padding: 8px 16px;
      }

      .logout-btn:hover {
        background-color: #d32f2f;
      }

      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    `,
  ],
})
export class ConfirmLogoutDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmLogoutDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
