import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit{

  private router = inject(Router)
  // private $route = Inject(ActivatedRoute);



  ngOnInit(): void {
    // console.log(this.router.snapshot.url , "url odf minjkkkkkkkkkkkkk") ;
  }

  constructor(){

  }
  detectRoute() {
    const currentUrl = this.router.url

    if (currentUrl.includes('/checkInDetail/')) {
      return; 
    }
    this.router.navigate(['/checkInList' ])
  }






}
